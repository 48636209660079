import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/HomeComponent/Home";
import PrivacyPolicy from "./components/PrivacyPolicyComponent/PrivacyPolicy";
import Navbar from "./components/NavbarComponent/Navbar";
import Footer from "./components/FooterComponent/Footer";
import WhatsAppIcon from "./components/WhatsappIcon/WhatsappIcon";
import ReturnRefundPolicy from "./components/ReturnRefundPolicy/ReturnRefundPolicy";
import TermsAndConditions from "./components/TermsAndConditions/TermsAndConditions";
import Blog from "./components/BlogComponent/BlogComponent";
import Client from "./components/HomeComponent/ClientComponent/Client";

function App() {
  return (
    <div className="App">
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/client" element={<Client />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route
            path="/refund-and-return-policy"
            element={<ReturnRefundPolicy />}
          />
          <Route
            path="/terms-and-conditions"
            element={<TermsAndConditions />}
          />
          <Route path="/blogs" element={<Blog />} />
        </Routes>
        <Footer />
        {/* <WhatsAppIcon /> */}
      </Router>
    </div>
  );
}

export default App;
