import React, { useState, useRef, useEffect } from "react";
import "./Navbar.css";
import { HashLink as Link } from "react-router-hash-link";
import virtuatelogo from "../../images/Logo2.svg";
import Client from "../HomeComponent/ClientComponent/Client";

const Dropdown = React.forwardRef(({ isOpen, toggle }, ref) => {
  const handleDropdownClick = (event) => {
    toggle();
  };
  return (
    <div className={`dropdown ${isOpen ? "show" : ""}`} ref={ref}>
      <a
        href="https://one.dzylo.com/#/login"
        className="dropdown-link"
        onClick={handleDropdownClick}
        target="_blank"
      >
        <span>Dzylo One</span>
      </a>
      <a
        href="https://client.dzylo.com"
        className="dropdown-link"
        onClick={handleDropdownClick}
        target="_blank"
      >
        <span>Client App</span>
      </a>
      <a
        href="https://vtour.dzylo.com/"
        className="dropdown-link"
        onClick={handleDropdownClick}
        target="_blank"
      >
        <span>Virtual Tour</span>
      </a>
      <a
        href="https://www.dzylo.ai/"
        className="dropdown-link"
        onClick={handleDropdownClick}
        target="_blank"
      >
        <span>Dzylo AI</span>
      </a>
    </div>
  );
});

export default function Navbar() {
  const dropdownRef = useRef(null);
  const productsLinkRef = useRef(null);
  const [click, setClick] = useState(false);
  const [dropdown, setDropdown] = useState(false);
  const toggleClick = () => setClick(!click);
  const closeMobileMenu = () => setClick(!click);
  const toggleDropdown = (event) => {
    if (event) {
      event.preventDefault();
      event.stopPropagation();
      setDropdown((prevDropdown) => !prevDropdown);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target) &&
        (!productsLinkRef.current ||
          !productsLinkRef.current.contains(event.target))
      ) {
        setDropdown(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <nav className="navbar">
      <div className="navbar-container">
        <Link smooth to="/#" className="navbar-logo">
          <img className="logo" src={virtuatelogo} alt="Virtutate" />
        </Link>
        <div className="menu-icon" onClick={toggleClick}>
          <i className={click ? "fas fa-times" : "fas fa-bars"} />
        </div>
        <ul className={click ? "nav-menu active" : "nav-menu"}>
          <li className="nav-item">
            <Link to="/client" className="nav-links" onClick={closeMobileMenu}>
              About Us
            </Link>
          </li>
          <li className="nav-item">
            <a href="/blogs" className="nav-links">
              Tech Blog
            </a>
          </li>
          <li className="nav-item">
            <Link
              smooth
              className="nav-links"
              onClick={toggleDropdown}
              ref={productsLinkRef}
            >
              Our Products
              <i
                className={`fas ${
                  dropdown ? "fa-chevron-up" : "fa-chevron-down"
                } arrow-icon`}
              ></i>
            </Link>
            <Dropdown
              isOpen={dropdown}
              toggle={toggleDropdown}
              ref={dropdownRef}
            />
          </li>
          <li className="nav-item">
            <a
              style={{
                color: "white",
                textDecoration: "none",
              }}
              href="https://calendar.app.google/wYJnLx3UDZtAVcit7"
              className="nav-links request__demo"
              target="_blank"
            >
              Book Meeting
            </a>
          </li>
        </ul>
      </div>
    </nav>
  );
}
