import React from "react";
import daizy_logo from "../../../images/daizy-main-logo.svg";
import dzylo_logo from "../../../images/dzylo_logo.jpg";
import virtuate_logo from "../../../images/Logo2.svg";
import "./About.css";

export default function About() {
  return (
    <div className="about">
      <div className="jumbotron">
        <div className="jumbotron__content">
          <div className="text">
            <h1
              style={{
                fontWeight: "bold",
                fontSize: "50px",
                marginTop: "-70px",
              }}
            >
              AI Consulting And Products
            </h1>
          </div>

          <div className="consultation">
            <button>
                <a
                  href="https://calendar.app.google/wYJnLx3UDZtAVcit7"
                  target="_blank"
                  style={{
                    textDecoration: "none",
                    color: "inherit",
                  }}
                >
                  Free Consultation
                </a>
              </button>
          </div>


          <div className="ai-cards-container">
              <div className="ai-body">
                <div className="ai_content" style={{ padding: "20px 30px"}}>
                  {/* <h2>Try Our Interior AI Expert In Interior</h2> */}
                  <img src={daizy_logo} alt="Daizy Logo"></img>
                  <h4 style={{fontWeight: "400"}}>Your Co-pilot for Interior Design & Build Business</h4>
                  <button className="try-daizy-button">
                    <a
                      href="https://dzylo.ai/"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      Try Now
                    </a>
                  </button>
                </div>
              </div>
              <div className="ai-body">
                <div className="ai_content" style={{ padding: "20px 30px"}}>
                  {/* <h2>Try Our Interior AI Expert In Interior</h2> */}
                  <img src={dzylo_logo} alt="Daizy Logo"></img>
                  <h4 style={{fontWeight: "400"}}>AI Powered Buisness Management and Inventory</h4>
                  <button className="try-daizy-button">
                    <a
                      href="https://one.dzylo.com/#/signup"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      Buy Now
                    </a>
                  </button>
                </div>
              </div>
              <div className="ai-body">
                <div className="ai_content" style={{ padding: "20px 30px"}}>
                  {/* <h2>Try Our Interior AI Expert In Interior</h2> */}
                  <img src={virtuate_logo} alt="Daizy Logo"></img>
                  <h4 style={{fontWeight: "400"}}>Custom AI Chat-Bot For Your Sales And Social Handles</h4>
                  <button className="try-daizy-button">
                    <a
                      href="https://contact-us.dzylo.com/virtuate-ai"
                      target="_blank"
                      style={{
                        textDecoration: "none",
                        color: "inherit",
                      }}
                    >
                      Contact Us
                    </a>
                  </button>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  );
}
