import React from "react";
import "./Footer.css";
import isoLogo from "../../images/IsoCertification.png";
import { Link, Outlet } from "react-router-dom";

export default function Footer() {
  return (
    <>
      <div className="footer">
        <div className="contact__info col">
          <div
            class="iso"
            style={{
              display: "flex",
              gap: "1rem",
              marginTop: "-0.3rem",
            }}
          >
            <img
              src={isoLogo}
              style={{
                width: "150px",
              }}
            />
            <div
              class="iso-desc"
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "1rem",
                justifyContent: "center",
                alignItems: "center",
                marginLeft: "-1rem",
                marginBottom: "5px",
                fontSize: "14px",
              }}
            >
              <div
                style={{
                  fontSize: "large",
                }}
              >
                9001:2015 ISO Certified
              </div>
              <div
                style={{
                  fontSize: "large",
                }}
              >
                27001:2022 ISO Certified
              </div>
            </div>
          </div>
        </div>
        <div className="aboutus__links col">
          <div>
            <a href=" https://one.dzylo.com/#/signup">
              <p
                style={{
                  textTransform: "inherit",
                  marginBottom: "0",
                }}
              >
                Pricing
              </p>
            </a>
          </div>
          <div>
            <Link to="/privacy-policy">Privacy Policy</Link>
          </div>
          <div>
            <Link to="/refund-and-return-policy">Refund and Return Policy</Link>
          </div>
          <div>
            <Link to="/terms-and-conditions">Terms and Conditions</Link>
          </div>
        </div>

        <div className="contact__links">
          <a href="mailto:contact@virtuate.in" target="blank">
            <p>
              <i className="fa fa-at" />
              <span>contact@virtuate.ai</span>
            </p>
          </a>
          <a href="tel:+919873009536" target="blank">
            <p>
              <i className="fa fa-mobile-alt" />
              <span>+919873009536</span>
            </p>
          </a>
          <a href="https://goo.gl/maps/Kgmu2DkRqgRs18b19" target="blank">
            <p>
              <i className="fa fa-map-marker-alt" />
              <span>C-309, M3M Urbana, Sector 67, Gurgaon, 122102</span>
            </p>
          </a>
          <div className="product__links">
            <p className="follow">
              Follow Us on
              <span>
                <a
                  href="https://www.facebook.com/Dzylo-107776798181973"
                  target="blank"
                >
                  <i
                    className="fa fa-facebook"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </a>
                <a href="https://www.instagram.com/d.z.y.l.o/" target="blank">
                  <i
                    className="fa fa-instagram"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </a>
                <a
                  href="https://www.linkedin.com/company/dzylo/"
                  target="blank"
                >
                  <i
                    className="fa fa-linkedin"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </a>
                <a
                  href="https://api.whatsapp.com/send/?phone=7303889822&text=Greetings!! Can you contact me to discuss about virtuate offerings.&type=phone_number&app_absent=0"
                  target="_blank"
                  rel="noreferrer"
                >
                  <i
                    className="fa fa-whatsapp"
                    style={{
                      marginLeft: "10px",
                    }}
                  />
                </a>
              </span>
            </p>
          </div>
        </div>
      </div>
      <Outlet />
    </>
  );
}
