"use client";
import React, { useState, useEffect } from "react";
import "./BlogComponent.css";
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col } from "reactstrap";
const moment = require("moment");
const parse = require("html-react-parser").default;

export default function Blog() {
  const [mediumData, setMediumData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(
      `https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/engineering-ai-pulse`
    )
      .then((res) => res.json())
      .then((response) => {
        setMediumData(response.items);
        setIsLoading(false);
      })
      .catch((err) => console.log(err));
  }, []);

  const finalData = mediumData.slice(0, 6);
  return (
    <div>
      <div id="blog" className="container mt-3">
        {isLoading && <p>Fetching data from Medium!</p>}

        <Row>
          {finalData.map((article) => (
            <Col md="12" className="mb-3" key={article.guid}>
              <div id="blog-img">
                <Card>
                  {/* <CardImg top width="100%" src={article.thumbnail} alt="img" /> */}
                  <CardBody>
                    <CardTitle>
                      <a href={article.link}>{article.title}</a>
                    </CardTitle>
                    <CardSubtitle>
                      Published:{" "}
                      {moment(article.pubDate).format("dddd, MMMM Do YYYY")}
                      {/* <div className="content"> */}
                        {parse(article.content)}
                      {/* </div> */}
                    </CardSubtitle>
                  </CardBody>
                </Card>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
}
