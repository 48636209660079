import React from "react";
import "./Client.css";
import anita from "../../../images/Anita.jpeg";
import manoj from "../../../images/Manoj.jpeg";
import vivek from "../../../images/Vivek.jpeg";

const Client = () => {
  return (
    <>
      <div className="company-description">
        <h2>About Us – Why Choose Virtuate</h2>
        <p>
          Virtuate is a leading technology-driven company dedicated to
          revolutionizing industries through innovative and intelligent
          solutions. With a strong focus on innovation, excellence, and
          sustainability, we empower businesses to achieve operational
          efficiency, data-driven decision-making, and seamless connectivity.
          Our mission is to deliver exceptional value to clients and
          stakeholders worldwide while driving technological progress and
          sustainable growth.
        </p>
        <ul>
          <li>
            <strong>Optimize Operations:</strong> Streamline workflows, reduce
            operational costs, and improve decision-making with data-driven
            insights.
          </li>
          <li>
            <strong>Enhance Connectivity:</strong> Enable real-time monitoring,
            predictive maintenance, and reliable system performance for seamless
            operations.
          </li>
          <li>
            <strong>Drive Innovation:</strong> Uncover trends, forecast
            outcomes, and stay ahead of the competition with advanced analytical
            tools.
          </li>
          <li>
            <strong>Empower Collaboration:</strong> Foster seamless
            communication and efficient data sharing across teams for effective
            project execution.
          </li>
        </ul>
        <p>
          At Virtuate, we believe in building smarter, more sustainable systems
          that drive growth, efficiency, and excellence.
        </p>
      </div>

      <div className="owner-component">
        <h1>Our Leadership Team</h1>
      </div>
      <div className="owners">
        <div className="owner-card"> {/* Added card container */}
          <a
            href="https://www.linkedin.com/in/manoj-jindal-89041014/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={manoj} alt="Manoj Jindal" />
          </a>
          <h3>Manoj Jindal</h3>
          <h4>(Founder & CEO)</h4>
          <p>
            Visionary entrepreneur with a proven track record of building  tech prdocuts and leading high-growth companies. Passionate about leveraging technology to drive innovation and transform industries. Founder and CEO of Virtuate, a leading technology-driven company dedicated to revolutionizing industries through innovative and intelligent solutions.
          </p>
        </div>
        <div className="owner-card"> {/* Added card container */}
          <a
            href="https://www.linkedin.com/in/anitatailor22"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={anita} alt="Anita Tailor" />
          </a>
          <h3>Anita Tailor</h3>
          <h4>(Co-Founder & CTO)</h4>
          <p>
            Engineering leader with expertise in Retail, Analytics, ML/Data
            Engineering, and Devices. Passionate about building high-performing
            teams and driving innovation. Co-founder of Dzylo, leveraging
            technology to transform the interior design and build industry.
          </p>
        </div>
        <div className="owner-card"> {/* Added card container */}
          <a
            href="https://www.linkedin.com/in/vivek-rai-bb687483/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={vivek} alt="Vivek Rai" />
          </a>
          <h3>Vivek Rai</h3>
          <h4>(Co-Founder & COO)</h4>
          <p>
            Co-founder of Dzylo, a B2B SaaS platform revolutionizing the
            interior design and build industry. Proven experience in operations
            and project management. Strong background in civil engineering from
            IIT Bombay.
          </p>
        </div>
      </div>
    </>
  );
};

export default Client;
